import React, {useState, useEffect, useRef} from 'react';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import StatusMessage from '../components/StatusMessage'
import {IconButton} from '@mui/material/Button';
import {useNavigate, useParams } from 'react-router-dom';
import {defaultDate, addDefaultDateToValue} from '../services/functions'
import serverPost from '../services/serverPost'
import {fetchEmployees} from '../services/serverFetch'
import {search} from '../services/search'
import {EMPLOYEES} from '../services/constants'
import {allowedToUpdateByFields} from '../services/functions'
import SearchTemplate from '../components/SearchTemplate'
import FormTemplate from '../components/FormTemplate'
import EditTable from '../components/EditTable'
import {PrintFields, PrintA4, PrintKvitto, RenderKunduppgifter, RenderFields} from '../components/PrintComponentVatansever'
import {fieldsServiceRapport} from '../pages/ServiceRapport'
import {useReactToPrint} from 'react-to-print'
import { useSharedState } from '../store';

const tableName = 'tbl_service'
const tableNameCustomer = 'tbl_customer'
const searchView = 'view_service'


const styles = {
    container: {
        width:'100vw',
        marginTop:90,
        display: 'flex',
        flexDirection:'column',
//        alignItems: 'center',
//        justifyContent: 'center',
        //overflow:'auto',
    },
    item: {
        display:'block', 
        margin:'auto'
    },
    td: {
        backgroundColor:'transparent'
    },
    pre: {
        //fontFamily: 'Regular', 
        backgroundColor:'transparent',
        padding:0,
        margin:0, 
        whiteSpace: 'pre-wrap',       
        wordWrap: 'break-word',
    }
}

const disabledFunc = value => {    
    let disabled = false
    if (value.disabledSaveUt && value.utlamnadAv.length > 0) {
            disabled = true
    } 
    return disabled    
}

const formFieldsFunc=emp=>([
    {
        type:'radio',
        radioValues:emp,
        label:'Utlämnad av',
        name:'utlamnadAv',
        tooltip:'Namn på personen som lämnar tillbaka objektet efter reparation eller kostnadsförslag',
        required:true,
        hiddenIf:'annanUtlamnadAv',
        disabledFunc:disabledFunc
    },
    {
        type:'text',
        label:'Utlämnad av',
        name:'utlamnadAv',
        tooltip:'Namn på personen som lämnar tillbaka objektet efter reparation eller kostnadsförslag',
        required:true,
        notHiddenIf:'annanUtlamnadAv',
        disabledFunc:disabledFunc
    },
    {
        type:'checkbox',
        label:'Annan',
        name:'annanUtlamnadAv',
        tooltip:'Om den som utämnar reparerat gods är en annnan person än de 3 ovan nämnda',
        labelStyle:{fontWeight:100},
        checkedIf:'utlamnadAv',
        notCheckedIf:emp, //array
        disabledFunc:disabledFunc,

    },
    {
        type:'date',
        label:'Utlämnad datum',
        name:'utlamnadDate',
        tooltip:'Objektet har lämnats ut detta datum',
        notHiddenIf:'utlamnadAv',
        useDefaultDate:true,
        autoFocus:true,
        disabledFunc:disabledFunc
    },

])

const fieldsText = [
    {
        label:'Tekniker',
        name:'tekniker',
    },
    {
        type:'textarea',
        label:'Utförda åtgärder',
        name:'atgarder',
    },
    {
        type:'textarea',
        label:'Använt material',
        name:'material',
    },
    {
        label:'Materialkostnad',
        name:'materialkostnad',
    },
    {
        type:'number',
        label:'Arbetstid',
        name:'arbetstid',
    },
    {
        name:'garanti',
        label:'Reparationen har utförts på garantin',
        ignoreIfValueEmpty:true,
        ingnoreSemicolon:true,
        ignoreValue:true,
        notHiddenIf:'garanti'
    },
    {
        label:'Kostnad inkl moms',
        name:'kostnad',
    },
]

export const fieldsRapport = [
    {
        emptyRow:true,
    },
    {
        label:'Tekniker',
        name:'tekniker',
    },
    {
        type:'textarea',
        label:'Utförda åtgärder',
        name:'atgarder',
    },
    {
        type:'textarea',
        label:'Använt material',
        name:'material',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Reparationen utförs akut mot extra avgift',
        name:'akutPrioritet',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Reparationen har utförts på garantin',
        name:'garanti',
    },
    {
        type:'comment',
        label:'Kostnad inkl moms',
        name:'kostnad',
    },
]

const fieldsExtra = [
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'AKUT PRIORITET',
        name:'akutPrioritet',
        labelStyle:{fontWeight:700, color:'red', lineHeight:1.2}
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'GARANTI',
        name:'garanti',
        labelStyle:{fontWeight:700, color:'red', lineHeight:1.2}
    },
    {
        label:'Inlämningsnummer',
        name:'id',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Inlämnad',
        name:'inlamnadDate',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Mottagare',
        name:'mottagare',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Fabrikat',
        name:'fabrikat',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Modell',
        name:'modell',
        labelStyle:{lineHeight:1.2}
    },
    {

        label:'Felbeskrivning',
        type:'textarea',
        name:'felbeskrivning',
        tooltip:'Felbeskrivning av objektet',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc,
        maxlength:50000,
    },
    {
        name:'kompletterandeFelbeskrivning',
        label:'Kompletterande felbeskrivning',
        type:'textarea',
        tooltip:'Felbeskrivning av objektet',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc,
        ignoreIfValueEmpty:true,
        maxlength:50000,
    },
]    

const searchFields = [
    {label:'Inlämningsnummer', labelResult: 'Inlämningsnr', name:'id'},
    {label:'För- och efternamn', name:'namn'},
    {label:'Telefonnummer', name:'mobil'},	
    {label:'E-mail', name:'email'},	
]    	

const resultFields = [
    {label:'Nr', labelResult: 'Inlämningsnr', name:'id', onClick:true},
    {label:'Namn', name:'namn'},
    {label:'Telefon', name:'mobil'},	
    {label:'E-mail', name:'email'},	
    {label:'Fabrikat', name:'fabrikat'},	
    {label:'Modell', name:'modell'},	
    {label:'Utlämnad', name:'utlamnadDate'},	
]    	



export default () => {
    const params = useParams()
    const [list, setList] = useState([])
    const [value, setValue] = useState({})
    const [statusMessage, setStatusMessage] = useState({})
    const navigate = useNavigate()
    const [edit, setEdit] = useState(undefined)
    const [more, setMore] = useState(undefined)
    const [formFields, setFormFields] = useState(formFieldsFunc(EMPLOYEES))
    const [sharedState, setSharedState] = useSharedState()
    const kontaktadAvhamtningAv = value.kontaktadAvhamtningAv?value.kontaktadAvhamtningAv.length > 0:false

    useEffect(()=>{
        setSharedState({...sharedState, pageName:'Utlämning - Sökning'})
        if (params.id > 0) {
            search(searchView, {id:params.id}, handleSearchReply) 
        }
        fetchEmployees(setFormFields, formFieldsFunc)
    }, []) 

    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
    }

    const handleClickLine = rec => {
        let dates ={}

        setSharedState({...sharedState, pageName:'Utlämning - Sammanställning'})

        setValue({...rec, ...dates})
        setList([])
    } 

    const handleSaveCallback = reply => {
        const {status, record} = reply

        if (status === 'OK') {
            setValue({...value, disabledSaveUt:1})
            handleStatus({backgroundColor:'green'}, undefined)
            if (sharedState.modified) {
                setSharedState({...sharedState, modified:undefined})
            }    
            if (kontaktadAvhamtningAv === false) {
                alert('Notera: Servicerapport ' + value.id + ' ej klar !')
            }
            navigate('/home')
        } else {
            const message = 'FELMEDDELANDE: Servicerapporten kunde inte uppdateras med utlamanad uppgifter (i tabellen tbl_service)'
            handleStatus({backgroundColor:'red'}, message)
        }    
    }        

    const handleSave = () => {
        if (value.disabledSaveUt == 1) {
            handleStatus({backgroundColor:'green'}, 'Skriv ut utan att spara')
        } else {
            handleStatus({backgroundColor:'green'}, 'Uppdaterar servicerapporten i databasen')
            let utlamnadDate = value.utlamnadAv?value.utlamnadDate?value.utlamnadDate:defaultDate():undefined; // Ensure that defaultDate is set
            let record = {
                utlamnadAv:value.utlamnadAv,
                annanUtlamnadAv:value.annanUtlamnadAv,
                utlamnadDate,
                disabledSaveUt:value.utlamnadAv?1:0
            }
            // alert('Utlämning: handleSave:' + JSON.stringify(record))
            record = addDefaultDateToValue(formFields, record)
            
            serverPost('/updateRow', '', '', {tableName, record, id:value.id}, handleSaveCallback)
        }   
    }

    const removeEmptyVal = val => {   
        let newVal = {}
        Object.entries(val).map(it=> {
            if (it[1]) {
                newVal = {...newVal, [it[0]]:it[1]}
            }        
        })
        return newVal
    }

    const updateValueAfterSearch = rec =>
    {
        handleStatus({backgroundColor:'green'}, undefined)    
        setValue(removeEmptyVal(rec))
        setList([])
    }

    const handleSearchReply = reply => {
        if (reply.length === 0) {
            handleStatus({backgroundColor:'orange'}, 'Varning: Fick inget resultat vid sökning i database')
        } else if (reply.length === 1) {
            setSharedState({...sharedState, pageName:'Utlämning - Sammanställning'})
            const rec = reply[0]
            // alert(JSON.stringify(rec))
            updateValueAfterSearch(rec)
        } else {
            setSharedState({...sharedState, pageName:'Utlämning - Sökning'})
            setList(reply)
        }    
    }

    const setValueAndModified = value => {
        if (!sharedState.modified) {
            setSharedState({...sharedState, modified:true})
        }    
        setValue(value)
    }


    const handleSearch = searchFields => {
        search(searchView, searchFields, handleSearchReply) 
    }

    const handleReset = () => {
        setValue({})
        setList([])
    }

    const componentRefA4 = useRef();
    const componentRefKvitto = useRef();

    const handleAvbryt = () => {
        navigate('/home')
    }

    const handleSwitchRight = id => {
        if (sharedState.modified) {
            if (window.confirm('Vill du lämna sidan utan att spara data ?')) {    
                setSharedState({...sharedState, modified:undefined})
            } else {
                return
            }
        }    
        navigate('/sr/' + id)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRefKvitto.current,
        onBeforePrint: () => {
            handleSave()   
        },
        onAfterPrint:() => {
            navigate('/home')
        }
    });

    const handlePrintA4 = useReactToPrint({
        content: () => componentRefA4.current,
        onBeforePrint: () => {
            handleSave()   
        },    
        onAfterPrint:() => {
            navigate('/home')
        }
    });

    
    const buttons=[
        {
            style:{color:'black', borderColor:'black'},
            label:'Skriv ut',
            required:true,
            handleClick:handlePrint
        },    
        {
            style:{color:'black', borderColor:'black'},
            label:'Skriv ut A4',
            required:true,
            handleClick:handlePrintA4
        },    
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Spara',
            required:true,
            disabled:(value.disabledSaveUt|| edit)?true:undefined,
            handleClick:()=>handleSave()
        },    
        {
            style:{color:'black', borderColor:'black'},
            type:'button',            
            label:'Noteringar',
            disabled: value.note?undefined:true,
            handleClick:()=>setMore(!more)
        },    
        {
            style:{color:'black', borderColor:'black'},
            label:'Avbryt',
            handleClick:handleAvbryt
        },    
        {
            style:{color:'black', borderColor:'black'},
            icon:<SwitchRightIcon />,
            handleClick:()=>handleSwitchRight(value.id?value.id:0)
        },    
    
        /*
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Sök ny',
            handleClick:handleReset
        },
        */
    ]
    const labelStyle = {fontWeight:700}
    // <h1>{JSON.stringify(value)}</h1>
    return(    
        <>
            <PrintFields ref={componentRefKvitto} fields={fieldsServiceRapport} value={value} />
            <PrintA4 ref={componentRefA4}  value={value} />
            {value.id?
                <div style={styles.container}>
                        <div className='columns is-centered'>
                            <div className='column is-4 is-narrow'>
                                <RenderKunduppgifter 
                                    anchor={true} 
                                    akutPrioritet={true} 
                                    garanti={true} 
                                    value={value} 
                                    setValue={setValueAndModified} 
                                    edit={edit}
                                    setEdit={setEdit}
                                    disabled={disabledFunc(value)}
                                />

                                <table style={{marginTop:50}}>
                                    {fieldsText.filter(it => ((it.ignoreIfValueEmpty && (value[it.name]==0 || !value[it.name]))?false:true)).map(it=>
                                        <tr>
                                                <td style={it.labelStyle?it.labelStyle:labelStyle}>{it.label?it.label:null}{it.ingnoreSemicolon?null:':'}</td>
                                                <td style={styles.td}>
                                                    {
                                                        it.ignoreValue?null
                                                        :it.type==='textarea'?<pre style={styles.pre}>{value[it.name]}</pre>
                                                        :value[it.name]?value[it.name]:null
                                                    }
                                                </td>
                                        </tr>
                                    )}      
                                    {more?
                                        <tr>                                             
                                            <td style={labelStyle}>Notering:</td>
                                            <td style={styles.td}>
                                                    <pre style={styles.pre}>{value.note?value.note:'Ingen notering'}</pre>
                                            </td>
                                        </tr>    
                                    :null
                        
                                    }    
                                </table>   

                                <FormTemplate
                                    buttons={buttons}
                                    tableName={tableName} 
                                    fields={formFields} 
                                    value={value} 
                                    setValue={setValueAndModified}
                                    handleStatus={handleStatus}  
                                />
                            </div>    
                            <div className="column is-3">
                                    <RenderFields A4={true} fields={fieldsExtra} value={value} setValue={setValueAndModified} /> 
                            </div>
                        </div>             
                    <StatusMessage style={statusMessage.style} message={statusMessage.message} />
                </div>
            :
                <div style={styles.container}>
                    <div style={styles.item}>
                            <SearchTemplate 
                                searchView={searchView}
                                searchFields={searchFields}
                                setValue={setValue} 
                                setList={setList} 
                                handleSearch={handleSearch}
                                handleStatus={handleStatus}
                            />
                            <EditTable 
                                tableName={tableName}
                                resultFields={resultFields}
                                list={list} 
                                setList={setList} 
                                handleStatus={handleStatus}  
                                handleClickLine={handleClickLine}
                            />
                            <StatusMessage style={statusMessage.style} message={statusMessage.message} />
                    </div>
                </div>
            }   
        </>
    )
} 