
import React from 'react';

// SearchField
export default props => {
    const {fld, disabled, value, setValue, handleKeyPress} = props
    const tooltip=fld['tooltip']?fld['tooltip']:undefined
    const handleChange = e => setValue({...value, [e.target.name]:e.target.type==='checkbox'?e.target.checked:e.target.value})
    const labelStyle={fontWeight:700, ...props.labelStyle?props.labelStyle:{}}
    const fldStyle={width:300}
    return(
        <p>
            {fld.label?
                <tooltip title={tooltip}>
                    <label style={labelStyle}>
                            {fld.label}
                    </label>    
                </tooltip>
            :
                null
            }    
            <br/>
            &nbsp;
            {fld.name?
                <input {...fld} 
                    type={fld.type} 
                    name={fld.name} 
                    value={value?.[fld.name]?value[fld.name]:''} 
                    style={fld.style?fld.style:fldStyle} 
                    //placeholder={fld.label?fld.label:fld.name} 
                    checked={fld.type==='checkbox'?value?.[fld.name]==1?1:0:undefined}
                    onChange={handleChange} 
                    disabled={disabled?disabled:false}
                    onKeyPress={handleKeyPress}
                    autoFocus={fld.autoFocus}
                />
            :
                null
            }    
            
        </p>
    )
}
