import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import StatusMessage from '../components/StatusMessage'
import { useSharedState } from '../store';

const system = process.env.REACT_APP_ENVIRONMENT==='development'?'DEV':process.env.REACT_APP_ENVIRONMENT==='test'?'TEST':'PROD'
const color = system !== 'PROD'?'yellow':"#FFFFFF"

const styles = {
    container:{
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'rgba(32,32,32,1)',
        color,
        style:'absolute',
        height:'CALC(100vh \- 50px'
    },
    row:{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    col:{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding:20,
    },
    button:{
        marginLeft:'5px',
        marginRight:'5px',
        marginBottom:'10px',
        borderWidth:'2px',
        height:50,
        color,
        borderColor:color,
        backgroundColor:'transparent'
    }

}

const Home = () => {
    const  [email, setEmail] = useState(undefined)
    const [value, setValue] = useState({})
    const [sharedState, setSharedState] = useSharedState()
    const navigate = useNavigate()
    const handleClickLine = (lnk, pageName)  => {
        setSharedState({...sharedState, pageName})
        navigate(lnk)
    }
    const auth = getAuth()

    useEffect(()=>onAuthStateChanged(auth, user => {
        setEmail(user.email)
    }), [])

    const handleChange = e => {
        setValue({...value, [e.target.name]:e.target.value})    
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleClickLine('/sr/' + value.id, 'Servicerapport')
        }
    }

    return(
        <div style={styles.container}>
            {email?
                <>
                    <div className='columns' style={{display:value.id?'none':'flex'}}>
                        <Button  
                            variant="outlined" 
                            type="button" 
                            style={styles.button}  
                            onClick={()=>handleClickLine('/kunduppgifter', 'Inlämning kunduppgifter')}
                        >
                            Inlämning
                        </Button>    
                    </div>
                    <div style={styles.col}>
                        <input autoFocus 
                            style={{fontSize:20, backgroundColor:'lightYellow', borderColor:color, color:'black'}} 
                            name='id' placeholder="Inlämningsnummer" 
                            value={value.id} 
                            type="number" 
                            onChange={handleChange} 
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div style={styles.col}>
                    <Button variant="outlined" type="button" style={styles.button}  onClick={()=>handleClickLine('/sr' + (value.id?'/' + value.id:'') , 'Servicerapport')}>
                        Servicerapport 
                    </Button>    
                    <Button variant="outlined" type="button" style={styles.button}  onClick={()=>handleClickLine('/ut' + (value.id?'/' + value.id:'') , 'Utlämining')}>
                            Utlämning 
                    </Button>    
                    </div>
                </>
            :
                <Button variant="outlined" type="button" style={styles.button}  onClick={()=>handleClickLine('/signin', 'Vänligen logga in')}>
                    Logga in
                </Button>    
        }    
        </div>
    )
}

export default Home


//<div style = {{...styles.img, backgroundImage: `url(${Image})`}} />
